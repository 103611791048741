<template>
  <div class="flex flex-col sm:mx-6 mt-6 py-6 px-6 sm:px-16">
    <AssetTitle title="Activity" />
    <TokenHistoryTable />
  </div>
</template>

<script>
import AssetTitle from '@/components/AssetTitle';
import TokenHistoryTable from '@/components/TokenHistoryTable';

export default {
  name: 'Home',
  components: {
    AssetTitle,
    TokenHistoryTable,
  },
};
</script>
<style scoped>
.tokenCard:first-child {
  margin-right: auto;
}

.tokenCard:last-child {
  margin-left: auto;
}
</style>
